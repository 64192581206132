import * as Lucide from "lucide-react";
import { useState } from "react";
import { useFetcher, type ActionFunctionArgs } from "react-router";
import { setConfigAttr } from "~/.server/source";
import { requireAppToken } from "~/.server/token";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";

export async function action(args: ActionFunctionArgs) {
  const token = await requireAppToken(args.request);
  const formData = await args.request.formData();
  const action = formData.get("action");
  if (action === "set-view-mode") {
    const sourceId = formData.get("sourceId") as string;
    const viewMode = formData.get("viewMode") as PrismaJson.ViewMode;
    await setConfigAttr(token.userId, sourceId, "viewMode", viewMode);
  }
}

function ListIcon({ className }: { className?: string }) {
  return (
    <svg viewBox="0 0 16 16" fill="none" className={className}>
      <g fill="currentColor" opacity="0.5">
        <circle cx="2" cy="4" r="1" />
        <circle cx="2" cy="8" r="1" />
        <circle cx="2" cy="12" r="1" />
      </g>
      <g stroke="currentColor" strokeWidth="2" strokeLinecap="round">
        <path d="M6 8L13 8" />
        <path d="M6 4L13 4" />
        <path d="M6 12L13 12" />
      </g>
    </svg>
  );
}

function GridIcon({ className }: { className?: string }) {
  return (
    <svg viewBox="0 0 16 16" fill="none" stroke="currentColor" strokeLinecap="round" className={className}>
      <g strokeWidth="2">
        <path d="M2 6L5 6" />
        <path d="M11 6L14 6" />
        <path d="M2 10L5 10" />
        <path d="M11 10L14 10" />
      </g>
      <path opacity="0.5" d="M8 4V12" />
    </svg>
  );
}

export function SourceOptions({ source }: { source: { config: PrismaJson.SourceConfig; sourceId: string } }) {
  const fetcher = useFetcher();
  const [viewMode, _setViewMode] = useState(source.config.viewMode ?? "list");
  function setViewMode(viewMode: PrismaJson.ViewMode) {
    _setViewMode(viewMode);
    const formData = new FormData();
    formData.append("action", "set-view-mode");
    formData.append("sourceId", source.sourceId);
    formData.append("viewMode", viewMode);
    fetcher.submit(formData, { method: "post", action: "/source-options" });
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button
          type="button"
          className="rounded-full size-8 text-foreground mx-1 p-0 group  flex items-center justify-center cursor-pointer focusable"
        >
          <div className="size-5.5 rounded-full bg-foreground/10 group-hover:bg-foreground/20 text-foreground/70 group-hover:text-foreground flex items-center justify-center">
            {viewMode === "list" ? <ListIcon className="size-4" /> : <GridIcon className="size-4" />}
          </div>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuGroup>
          <DropdownMenuLabel>Display as</DropdownMenuLabel>
          <DropdownMenuItem onSelect={() => setViewMode("list")}>
            <ListIcon className="size-4" />
            <span className="grow">List</span>
            {viewMode === "list" ? <Lucide.Check className="size-4" /> : null}
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => setViewMode("grid")}>
            <GridIcon className="size-4" />
            <span className="grow">Grid</span>
            {viewMode === "grid" ? <Lucide.Check className="size-4" /> : null}
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
