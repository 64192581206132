import * as Lucide from "lucide-react";
import { useState } from "react";
import { Link, useLoaderData, useNavigate } from "react-router";
import type { GetSourceSummaryShape } from "~/.server/source-summary";
import { Calendar } from "~/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "~/components/ui/popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "~/components/ui/tooltip";
import { formatDate, formatDateTimeShort } from "~/lib/utils";
import { episodeShortName } from "~/model/episode";
import { sourceSlug } from "~/model/source";
import { useUser } from "~/pages/with-user";
import { getSourceMeta } from "~/sources/config";
import type { loader } from "./.server/loader";
import { SourceOptions } from "./options";

export function EpisodeName({ sourceSummary }: { sourceSummary: GetSourceSummaryShape }) {
  const { allEpisodeKeys, indexEpisodeKey } = useLoaderData<typeof loader>();
  const user = useUser();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  function dateToEpisodeKey(date: Date) {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0];
  }

  function goToEpisode(date: Date | undefined) {
    if (date) {
      const episodeKey = dateToEpisodeKey(date);
      setOpen(false);
      navigate(`${sourceSlug(sourceSummary.source)}${episodeKey === indexEpisodeKey ? "" : `/${episodeKey}`}`);
    }
  }

  function disabledDate(date: Date) {
    const episodeKey = dateToEpisodeKey(date);
    return allEpisodeKeys.indexOf(episodeKey) < 0;
  }

  // TODO - combine and reuse calendar from EpisodeName
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          type="button"
          className="font-serif font-semibold text-xl uppercase cursor-pointer hover:bg-foreground/5 mx-1 pl-2 pr-1 py-0.5 rounded-md group flex flex-row items-center focusable"
        >
          {episodeShortName(sourceSummary.episode, user)}
          <Lucide.ChevronDown className="ml-1 size-4 group-hover:opacity-50  group-focus-visible:opacity-50 opacity-0" />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 flex flex-col items-center" align="start">
        <Calendar
          id="episodeDate"
          mode="single"
          selected={sourceSummary.episode.untilTime}
          onSelect={goToEpisode}
          disabled={disabledDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}

export function SummaryPeriod({ summary }: { summary: { fromTime: Date; untilTime: Date } }) {
  const user = useUser();
  if (summary.untilTime.getTime() - summary.fromTime.getTime() <= 24 * 60 * 60 * 1000) {
    // if the period is 24 hours, don't show it
    return null;
  }
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div className="text-sm text-muted-foreground pr-2">
          <span>{formatDate(summary.fromTime, user, { month: "short", day: "numeric" })}</span>
          <span> → </span>
          <span>{formatDate(summary.untilTime, user, { month: "short", day: "numeric" })}</span>
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <p>
          {formatDateTimeShort(summary.fromTime, user)} → {formatDateTimeShort(summary.untilTime, user)}
        </p>
      </TooltipContent>
    </Tooltip>
  );
}

export function SourceHeader({
  sourceSummary,
  showFullName,
}: { sourceSummary: GetSourceSummaryShape; showFullName: boolean }) {
  const source = sourceSummary.source;
  const meta = getSourceMeta(source.sourceType);

  return (
    <h1 className="text-foreground flex flex-row items-center border-b border-divider py-1 px-1 sticky z-20 top-0 bg-background h-12">
      <Link
        to={`${sourceSlug(source)}`}
        className="font-serif font-semibold text-xl text-primary uppercase pr-2 py-0.5 rounded-md hover:bg-foreground/5 focusable"
      >
        <span className="inline-flex items-center justify-center text-primary w-8">
          <img src={meta.iconMono} alt={meta.name} />
        </span>
        <span>{showFullName ? source.sourceName : meta.name}</span>
      </Link>
      <span className="pr-2 mr-1 border-r border-divider inline-block h-6" />
      <EpisodeName sourceSummary={sourceSummary} />
      <div className="grow" />
      <SummaryPeriod summary={sourceSummary} />
      <SourceOptions source={source} />
    </h1>
  );
}
