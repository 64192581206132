import { Link, useLoaderData } from "react-router";
import type { loader } from "./.server/loader";

function NavButtonIcon({ rotation }: { rotation: number }) {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      className="size-5.5 rounded-full bg-foreground/10 group-hover:bg-foreground/20 stroke-foreground/70 group-hover:stroke-foreground"
    >
      <path
        d="M16 9L11 14L6 9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform={`rotate(${rotation} 11 11)`}
      />
    </svg>
  );
}

export function NavButton({ type, link }: { type: "prev" | "next"; link?: string }) {
  const rotation = type === "prev" ? 90 : -90;
  return link ? (
    <Link
      to={link}
      className="rounded-full size-8 text-foreground p-0 group  flex items-center justify-center cursor-pointer focusable"
    >
      <NavButtonIcon rotation={rotation} />
    </Link>
  ) : (
    <div className="rounded-full size-8 text-foreground p-0 flex items-center justify-center opacity-40">
      <NavButtonIcon rotation={rotation} />
    </div>
  );
}

export function EpisodeFooter() {
  const { previousEpisodeKey, nextEpisodeKey, indexEpisodeKey } = useLoaderData<typeof loader>();
  const nextEpisodeLink = nextEpisodeKey
    ? nextEpisodeKey === indexEpisodeKey
      ? "/"
      : `/${nextEpisodeKey}`
    : undefined;
  return (
    <div className="flex flex-row items-center px-8 py-6">
      <NavButton type="prev" link={previousEpisodeKey && `/${previousEpisodeKey}`} />
      <NavButton type="next" link={nextEpisodeLink} />
    </div>
  );
}
